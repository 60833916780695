import {Directive, HostListener} from '@angular/core';

declare var require: any;
const screenFull = require('screenfull');

@Directive({
    selector: '[appToggleFullscreen]'
})
export class ToggleFullscreenDirective {
    @HostListener('click') onClick() {
        if (screenFull.isEnabled) {
            screenFull.toggle();
        }
    }
}

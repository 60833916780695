import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AttachmentViewModel, DownloadAttachmentRequest} from '../../models/attachment.model';
import {AttachmentService} from './attachment.service';
import {LoadingService} from '../../services/loading.service';


@Component({
    selector: 'app-attachment-viewer',
    templateUrl: './attachment-viewer.component.html',
    styleUrls: ['./attachment-viewer.component.scss'],
    providers: [AttachmentService]
})
export class AttachmentViewerComponent implements OnInit {
    @Input() attachments: AttachmentViewModel[] = [];
    @Input() showAttachmentLabel = true;
    @Input() isModal = false;
    @Input() taskCode: string;

    constructor(public modalRef: BsModalRef,
                private attachmentService: AttachmentService,
                private spinner: LoadingService) {
    }

    ngOnInit(): void {
    }

    download(attachment: AttachmentViewModel) {
        const data: DownloadAttachmentRequest = {
            id: attachment?.id,
            attachmentType: attachment?.attachmentType
        };
        this.spinner.loadingOn();
        this.attachmentService.downloadAttachment(data).subscribe((resp: Blob) => {
            this.spinner.loadingOff();
            const blob = new Blob([resp], {type: resp.type});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = resp.type;
            link.click();
            window.URL.revokeObjectURL(link.href);
        }, (error) => {
            this.spinner.loadingOff();
        });
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthResponse} from '../../shared/models/user.model';
import {map} from 'rxjs/internal/operators';
import {Observable, Subject} from 'rxjs';
import {RegisterApiResponse, RegisterResponse} from '../../shared/models/register.model';
import {environment} from '../../../environments/environment';
import {CustomerDropDownApi} from '../../shared/models/customer-dropdown.model';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    company_id = environment.company_id;
    onLoginDataUpdate: Subject<boolean> = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    login(loginData: { email: string, password: string, company_id: number }): Observable<AuthResponse> {
        loginData.company_id = this.company_id;
        return this.http.post<AuthResponse>(`customer/customerLogin`, loginData)
            .pipe(map(authResponse => {
                    const loggedUser = authResponse.customer;
                    const accessToken = authResponse.access_token;
                    localStorage.setItem('userData', JSON.stringify(loggedUser));
                    localStorage.setItem('accessToken', accessToken);
                    this.onLoginDataUpdate.next(true);
                    return authResponse;

                })
            );
    }

    public get getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    logout() {
        localStorage.clear();
        this.onLoginDataUpdate.next(true);
    }

    register(signInDataData: RegisterResponse): Observable<RegisterApiResponse> {
        signInDataData.company_id = this.company_id;
        return this.http.post<RegisterApiResponse>(`customer/customerSignup`, signInDataData);
    }

    getDropdown(): Observable<CustomerDropDownApi> {
        return this.http.get<CustomerDropDownApi>(`customer/get_customer_form_data?company_id=${this.company_id}`);
    }

    verifyEmail(data: any) {
        return this.http.get<any>(`customer/validateCustomerEmail?email=${data.email}&token=${data.token}&company_id=${this.company_id}`);
    }


}

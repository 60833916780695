<div class="outline-bg">
    <div class="input-group">
        <input
                class="form-control"
                (keydown.enter)="$event.preventDefault()"
                placeholder="Search Location"
                type="text"
                #search/>
        <span class="input-group-append"><div class="input-group-text bg-white border-left-0 "><i
                class="fa fa-search"></i></div></span>
    </div>

    <agm-map
            [latitude]="latitude"
            [longitude]="longitude"
            [zoom]="zoom">
        <agm-marker
                [latitude]="latitude"
                [longitude]="longitude"
        ></agm-marker>
    </agm-map>

</div>

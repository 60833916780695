import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem('accessToken');
        request = request.clone({
            url: `${environment.baseApiUrl}${request.url}`
        });
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-Web-Customer': 'true'
                }
            });
        }
        request = request.clone({
            setHeaders: {
                'X-Web-Customer': 'true'
            }
        });
        return next.handle(request);
    }
}

<div class="modal-header" *ngIf="isModal">
    <h4 class="modal-title">Attachments <span *ngIf="taskCode">({{taskCode}})</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body attachment-modal mb-5">
    <label *ngIf="!isModal && showAttachmentLabel" class="p-text-secondary">Attachments</label>

    <table class="table">
        <thead>
        <tr>
            <th style="width: 5%"><label>#</label></th>
            <th style="width: 40%"><label>Description</label></th>
            <th style="width: 10%"><label>Type</label></th>
            <th style="width: 15%"><label>Size</label></th>
            <th style="width: 20%"><label>Uploaded Date</label></th>
            <th style="width: 10%"><label>Action</label></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of attachments; index as rowIndex">
            <td class="mt-2 mt-sm-0">
                {{rowIndex + 1}}
            </td>
            <td>
                {{attachment?.description}}
            </td>
            <td>
                <app-attachment-icon [url]="attachment?.url"></app-attachment-icon>
            </td>
            <td>
                {{attachment?.size}}
            </td>
            <td>
                {{attachment?.created_at | date:'dd/MM/yyyy'}}
            </td>
            <td class="mb-3 mb-sm-0">
                <button class="btn btn-outline-green btn-circle" type="button"
                        (click)="download(attachment)">
                    <i class="fa fa-download"></i>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>



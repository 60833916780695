<div class="custom-datatable m-t-10">
    <div class="table-responsive">
        <ngx-datatable
                class="bootstrap"
                #table
                [cssClasses]="datatableConfig.cssClasses"
                [rows]="tableData?.data"
                [columns]="columns"
                [columnMode]="columnMode"
                [headerHeight]="datatableConfig.headerHeight"
                [footerHeight]="datatableConfig.footerHeight"
                [rowHeight]="datatableConfig.rowHeight"
                [limit]="tableData?.per_page"
                [count]="tableData?.total"
                [offset]="tableData?.current_page-1"
                [selectionType]="selectionType"
                (page)="onPageChanges($event)"
                [externalPaging]="true"
                [loadingIndicator]="loadingIndicator"
        >
        </ngx-datatable>
    </div>
</div>

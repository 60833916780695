<div class="row">
    <div class="col-12 mb-3">
        <div class="custom-file-upload" (click)="resetResponse();uploadInput.click()">
            <input type="text" readonly [value]="uploader.queue.length + ' File Selected'" class="form-control d-none"
                   (click)="resetResponse();uploadInput.click()" id="inlineFormInputGroup" placeholder="Username">
            <div class="p-text-center">
                <h4><i class="fa fa-cloud-upload" aria-hidden="true"></i><br> Upload </h4>
                <span>Store Document And Files </span>
            </div>
        </div>
        <input type="file" class="form-control" (onFileSelected)="uploadAll()" ng2FileSelect [uploader]="uploader"
               #uploadInput [multiple]="multipleFileUpload" hidden/>
    </div>
</div>

<div class="row">
    <div class="col-sm-10 offset-1" *ngIf="uploader.progress">
        <div>
            Queue progress:
            <div class="progress" style="">
                <div class="progress-bar" role="progressbar"
                     [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
            <h6 class="text-danger" *ngIf="uploader.isUploading">Uploading</h6>
        </div>
    </div>
    <div class="col-sm-10 offset-1 mt-4" *ngIf="fileForm.get('files')['controls'].length>0">
        <form [formGroup]="fileForm" autocomplete="off" (ngSubmit)="onSubmit()">
            <table class="table table-bordered">
                <thead style="background-color: #fafafa;font-size: small;font-style: normal;">
                <tr>
                    <th width="50%">Description</th>
                    <th class="p-text-center">File Type</th>
                    <th class="p-text-center">Size</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody formArrayName="files">
                <tr *ngFor="let files of fileForm.get('files')['controls']; let i = index;">
                    <ng-container [formGroupName]="i">
                        <td>
                            <input type="text" class="form-control" formControlName="description" id="description"
                                   name="description" required>
                        </td>
                        <td>
                            <app-attachment-icon [url]="files.get('tmpUrl').value"></app-attachment-icon>
                        </td>
                        <td>{{files.get('size').value}}</td>
                        <td>
                            <a class="btn btn-sm btn-icon btn-outline-dark rounded-circle mr-1 mb-1"
                               data-toggle="tooltip" data-placement="top" title="Delete"
                               (click)="removeFile(i,files.get('url').value)" [routerLink]="">
                                <i class="fa fa-trash"></i>
                            </a>
                        </td>

                    </ng-container>
                </tr>
                </tbody>
                <tbody *ngIf="fileForm.get('files')['controls'].length===0">
                <tr class="text-danger">No selected files</tr>
                </tbody>
            </table>
            <button class="btn btn-pill btn-lightblue float-right mt-2"
                    *ngIf="fileForm.get('files')['controls'].length>0">
                Confirm & Save
            </button>
        </form>
    </div>
</div>
<br>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {INg2LoadingSpinnerConfig} from 'ng2-loading-spinner';
import {TableApiService} from '../table-api.service';
import {TableService} from '../table.service';
import {Subscription} from 'rxjs';
import {ColumnMode, SelectionType} from '@swimlane/ngx-datatable';
import {DEFAULT} from '../../../config/theme.config';
import {DATA_TABLE_CONFIG} from '../../../config/datatable.config';
import {UntilDestroy} from '@ngneat/until-destroy';

type Params = {
    [key: string]: any
};

@UntilDestroy()
@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})


export class TableComponent implements OnInit, OnDestroy {
    @Input() columns: any[];
    @Input() url: string;
    params: Params = {
        per_page: 15,
        page: 1,
    };
    datatableConfig = DATA_TABLE_CONFIG;
    loadingIndicator = false;
    loadingConfig: INg2LoadingSpinnerConfig = DEFAULT.btnLoadingConfig;
    formDataLoad = false;
    tableData: any;
    dts: Subscription;
    dt: Subscription;
    selectionType = SelectionType.single;
    columnMode = ColumnMode.force;

    constructor(private tableApiService: TableApiService, private tableService: TableService) {
    }

    ngOnInit(): void {
        this.getPagination();
        this.dt = this.tableService.datatbale.subscribe(() => {
            this.getPagination();
        });
        this.dts = this.tableService.datatbaleSearch.subscribe((p: object) => {
            this.params = {
                per_page: 10,
                page: 1,
            };
            this.params = {...this.params, ...p};
            this.getPagination();
        });
    }

    onPageChanges(pageInfo) {
        this.params.page = pageInfo.offset + 1;
        this.getPagination();
    }

    getPagination() {
        this.loadingIndicator = true;
        this.tableApiService.getPagination(this.url, this.params).subscribe(response => {
            this.tableData = response.data;
            this.loadingIndicator = false;
        }, () => {
            this.loadingIndicator = false;
        });
    }

    ngOnDestroy() {
        this.dts.unsubscribe();
        this.dt.unsubscribe();
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {ToastrAlertService} from '../../services/toastr-alert.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MaximumFileLabel, MaximumFileSize, ValidAttachmentTypes, ValidImageTypes} from '../../config/attachment.config';
import {environment} from '../../../../environments/environment';
import {FileUploaderService} from './file-uploader.service';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

    @Input() uploaderType = null;
    @Input() showAttachmentLabel = true;
    @Output() onFileUploadComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() uploadedFiles: EventEmitter<any> = new EventEmitter<any>();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() multipleFileUpload = false;
    maxUploadSizeLabel: string;
    uploader: FileUploader;
    hasBaseDropZoneOver: boolean;
    hasAnotherDropZoneOver: boolean;
    authToken = null;
    company_id = environment.company_id;
    URL = `${environment.baseApiUrl}customer/uploadCustomerOrderAttachment`;
    fileSize = null;
    acceptType: string;
    fileUploadComplete = false;
    fileForm: FormGroup;
    subscription: Subscription;


    constructor(private authenticationService: AuthenticationService,
                private fileUploaderService: FileUploaderService,
                private toastrMessage: ToastrAlertService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.setUploaderType();
        this.getToken();
        this.setFileUploader();

    }

    initForm() {
        this.fileForm = new FormGroup({
            files: new FormArray([])
        });
    }

    setUploaderType() {
        this.fileSize = MaximumFileSize;
        this.maxUploadSizeLabel = MaximumFileLabel;
        if (this.uploaderType === 'common') {
            this.acceptType = ValidAttachmentTypes;
        } else if (this.uploaderType === 'image') {
            this.acceptType = ValidImageTypes;
        }
    }

    removeFile(index: number, filePath: string) {
        const controller = this.fileForm.get('files') as FormArray;
        const path = [];
        path[0] = filePath;
        this.fileUploaderService.removeFile(path).subscribe((resp) => {
            controller.removeAt(index);
            if (this.fileForm.get('files')?.value?.length === 0) {
                this.uploader.clearQueue();
            }
            this.toastrMessage.success('File removed successfully');
        }, (error) => {
            this.toastrMessage.error(error.error.message);
            console.log(error);
        });

    }

    setFileUploader() {
        this.uploader = new FileUploader({
            url: this.URL,
            additionalParameter: {company_id: this.company_id},
            authToken: 'Bearer ' + this.authToken,
            headers: [{ name: 'X-Web-Customer', value: 'true' }],
            maxFileSize: this.fileSize
        });
        this.uploader.onWhenAddingFileFailed = (item) => {
            this.toastrMessage.error('The file may not be greater than 5120 kilobytes.');
        };

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
    }

    resetResponse() {
        if (this.subscription) {
            this.subscription.unsubscribe();

        }
    }

    uploadAll() {
        this.isValid.emit(false);
        this.uploader.uploadAll();
        this.subscription = this.uploader.response.subscribe((res) => {
            this.fileUploaded(res);
        }, (error) => {
            this.toastrMessage.error(error.error.message);
        });
    }

    fileUploaded(response) {
        const obj = JSON.parse(response);
        this.fileUploadComplete = true;
        if (obj.success) {
            const controller = this.fileForm.get('files') as FormArray;
            controller.push(
                new FormGroup({
                    url: new FormControl(obj.data.file_name),
                    tmpUrl: new FormControl(obj.data.temporary_url),
                    size: new FormControl(obj.data.file_size),
                    description: new FormControl(null, [Validators.required, Validators.maxLength(150)])
                })
            );
            this.fileForm.updateValueAndValidity();
        } else {
            this.uploader.clearQueue();
            this.fileUploadComplete = false;
            this.toastrMessage.error(obj.message.file[0]);
        }
    }

    getToken() {
        this.authToken = this.authenticationService.getAccessToken;
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onSubmit(): void {
        if (this.fileForm.invalid) {
            this.toastrMessage.error('Description field is required');
            return;
        }
        this.isValid.emit(true);
        const formValue = this.fileForm.value;
        this.onFileUploadComplete.emit(formValue);
    }


}

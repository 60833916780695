import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastrAlertService {
    options: any = {
        timeOut: 5000,
        closeButton: true
    };

    constructor(public toastr: ToastrService) {
    }

    success(message?: string) {
        this.toastr.success(message || '', 'Success', this.options);
    }

    error(message?: string) {
        this.toastr.error(message || '', 'Error', this.options);
    }

    info(message?: string) {
        this.toastr.info(message || '', 'Info', this.options);
    }

    warning(message?: string) {
        this.toastr.warning(message || '', 'Warning', this.options);
    }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TableService {
    public datatbale = new Subject();
    public datatbaleSearch = new Subject();

    constructor() {
    }

    reDraw() {
        this.datatbale.next(Math.random());
    }

    filter(params) {
        this.datatbaleSearch.next(params);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DownloadAttachmentRequest} from '../../models/attachment.model';


@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(private http: HttpClient) {
    }

    downloadAttachment(attachment: DownloadAttachmentRequest) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            responseType: 'blob'
        });
        return this.http.get(`customer/download-order-attachment?id=${attachment?.id}&attachmentType=${attachment?.attachmentType}`, {
            headers,
            responseType: 'blob' as 'json'
        });
    }
}
